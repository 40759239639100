import React from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Link } from 'react-router-dom';

function App() {

  return (
    <div className="App">
      <h1 className="header-title">无锡星环智能信息科技有限公司</h1>
      <ul className="navbar navbar-expand-lg navbar-light " role="nav">
        <li><Link to="/">首页</Link></li>
        <li><Link to="/check21day">星环打卡</Link></li>
        {/* <li><Link to="/about">关于我们</Link></li> */}
      </ul>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/check21day" element={<Check21day />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );

}

const Home = () => {
  return <div>
    <section className="download  text-center" id="download">
        <div className="container">
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <h2 className="section-heading">星环智能有许多专业的服务项目<br/>等待您来探索！</h2>
                    {/* <p>即将上线，敬请期待。(目前只支持Android设备)</p> */}
                    {/* <div className="badges">
                        <a className="badge-link" href="https://shouji.baidu.com/software/25812977.html" target="_blank"><img src="/img/baidu-app-store.png" alt="" /></a>
                        <a className="badge-link" target="_blank" href="https://play.google.com/store/apps/details?id=com.fm618.dev.starringcheckon"><img src="/img/google-play-badge.svg" alt="" /></a>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
    {/* <section className="cta">
        <div className="cta-content">
            <div className="container">
                <h2>停止空想 开始行动.</h2>
            </div>
        </div>
        <div className="overlay"></div>
    </section> */}
  </div>
}

const About = () => {
  return <div>这里是卡拉云的网站，你当前在</div>
}

const Check21day = () => {
  return <div>
    <header className="masthead">
        <div className="container h-100">
            <div className="row h-100">
                <div className="col-lg-7 my-auto">
                    <div className="header-content mx-auto">
                        <h1 className="mb-5">星环打卡让您专注一件事，坚持21天，您不仅能养成一个好习惯，还能解锁一个新技能呦！<br />快，行动起来！<br /><span style={{fontSize: "medium"}}>(使用本软件无需任何费用)</span></h1>
                        <a href="javascript:void(0)" className="btn btn-outline btn-xl js-scroll-trigger">暂停下载，即将归来!</a>
                    </div>
                </div>
                <div className="col-lg-5 my-auto">
                    <div className="device-container">
                        <div className="device-mockup iphone6_plus portrait white">
                            <div className="device">
                                <div className="screen">
                                    
                                    <img src="/img/demo-screen-1.jpg" className="img-fluid" alt="" />
                                </div>
                                <div className="button">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
  </div>
}

const Footer = () => {
  return <div className="footer container">
  <p>&copy;2018 无锡星环智能信息科技有限公司. All Rights Reserved.</p>
  <ul className="list-inline">
      <li className="list-inline-item">
          <a href="#">Privacy</a>
      </li>
      
      <li className="list-inline-item">
          <a target="_blank" href="http://www.beian.miit.gov.cn">苏ICP备19004872号-2</a>
      </li>
      
      <li className="list-inline-item">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32021102000845"><img src="/img/beian.png"  />苏公网安备 32021102000845号</a>
      </li>

  </ul>
</div>
}

const NotFound = () => {
  return <div>你来到了没有知识的荒原</div>
}

export default App;
